import React, { useContext, useEffect, useState, Fragment } from 'react';
import axios from 'axios';

import MyContext from '../context/MyContext';

import FormCheck from './FormCheck';
import SendButton from './SendButton';
import TextArea from './TextArea';

const btnDisabled = {
  cursor: 'not-allowed',
  borderStyle: 'dashed',
  borderColor: '#ccc',
  color: '#ccc'
};

const DynamicForm = props => {
  const formContext = useContext(MyContext);
  const {
    id,
    error,
    setError,
    setLoading,
    isLoading,
    newData,
    setData,
    prevPage,
    setPrevPage,
    codeLocked,
    setCodeLocked
  } = formContext;

  let title = props.propsdata.datatitle;
  let text = props.propsdata.datatext;
  let pagecount = props.pagecount;
  let currentpagenumber = props.currentpagenumber;
  let pagehistory = props.pagehistory;

  const [pageData, setPageData] = useState({
    // wrapped in [], because it's imported dynamically from JSON!
    //[title]: 'ok', // if default value should be set
    [title]: '', // no default value
    [text]: '',
    send: false,
    textlength: 300,
    prev: false
  });

  useEffect(() => {
    const postdata = {
      id: parseInt(id),
      code_used: 1
    };

    axios({
      method: 'POST',
      url: '/api/update_codes.php',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: postdata
    })
      .then(res => {
        setCodeLocked(true);
      })
      .catch(err => console.log(err));

    let objecttitle, objecttitlevalue, objecttitleNext, objecttitlevalueNext;

    if (prevPage === true && currentpagenumber > newData.length) {
      setPrevPage(false);
    }

    if (newData.length > 0) {
      objecttitle = Object.values(newData[0]);
      objecttitlevalue = objecttitle[1];

      objecttitleNext = Object.values(newData);
      objecttitlevalueNext = Object.values(objecttitleNext[0]);
    }
    if (
      prevPage &&
      newData[currentpagenumber - 1] &&
      objecttitlevalue === objecttitlevalueNext[1]
    ) {
      setPageData({
        [title]: newData[currentpagenumber - 1][title],
        [text]: newData[currentpagenumber - 1][text],
        send: false
      });
    } else {
      setPageData({
        // [title]: 'ok', // if default value should be set
        [title]: '', // no default value
        [text]: '',
        textlength: 300
      });
    }
  }, [
    id,
    newData,
    prevPage,
    text,
    title,
    currentpagenumber,
    formContext.newData,
    pageData.prev,
    setPrevPage,
    codeLocked,
    setCodeLocked
  ]);

  const onChange = e => {
    setPageData({
      ...pageData,
      [e.target.name]: e.target.value,
      textlength: 300 - e.target.value.length
    });
    if (error) {
      setError(false);
    }
  };

  const nextPage = e => {
    e.preventDefault();

    let pageDataValues = Object.values(pageData);
    if (pageDataValues[0].length < 1) {
      setError(true);
      return;
    } else {
      setLoading(true);
      setError(false);
      setTimeout(() => {
        if (prevPage === true) {
          setTimeout(() => {
            pagehistory.push(`/page/${props.pagenumber + 1}`);
            setLoading(false);
          }, 100);
        } else if (pagecount === currentpagenumber) {
          setData(pageData);
          setTimeout(() => {
            setLoading(false);
          }, 100);
          setPageData({ ...pageData, send: true });
        } else {
          setData(pageData);
          pagehistory.push(`/page/${props.pagenumber + 1}`);
          setTimeout(() => {
            setLoading(false);
          }, 100);
          setPageData({ ...pageData, send: false });
        }
      }, 100);
    }
  };

  const getPrevPage = e => {
    e.preventDefault();
    setLoading(true);
    setPrevPage(true);

    if (currentpagenumber === 1) {
      return;
    } else {
      pagehistory.push(`/page/${props.pagenumber - 1}`);
      setLoading(false);
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    setLoading(true);

    const data = {
      id: parseInt(props.id),
      authcode: props.authcode,
      code_used: 1,
      newData: newData
    };

    axios({
      method: 'POST',
      url: '/api/create_answer.php',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    })
      .then(res => {
        if (res.data.message === 'success') {
          setTimeout(() => {
            pagehistory.push('/vielen-dank');
            setLoading(false);
          }, 1500);
        } else {
          setTimeout(() => {
            alert(
              `⚠️ Fehler bei der Übermittlung. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an Ihren für die Befragung zuständigen Ansprechpartner.\n\nFehlermeldung:\n${res.data}`
            );
          }, 1500);
        }
      })
      .catch(err => {
        alert(
          `⚠️ Fehler bei der Übermittlung. Bitte versuchen Sie es später nocheinmal oder wenden Sie sich an Ihren Ansprechpartner der Befragung.\n\nFehlermeldung:\n${err}`
        );
      });

    /* let csv = [];
    newData.map(item => {
      for (let [key, value] of Object.entries(item)) {
        console.log(`${key}: ${value}`);
        csv.push({
          title: `${key}: ${value}`,
          text: `${key}: ${value}`
        });
      }
      return true;
    });
    console.log(csv); */

    /* setTimeout(() => {
      pagehistory.push('/vielen-dank');
      setLoading(false);
    }, 1500); */
  };

  return (
    <Fragment>
      <div className="row py-3">
        <div className="col">
          <h3 className="font-weight-bold text-center">
            {props.maintitle.toUpperCase()}
          </h3>
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col px-5">
          <form>
            <div className="form-group row">
              <h5>
                {props.firsttitle} <span className="text-danger">*</span>
              </h5>
              <FormCheck
                name={[title]}
                value="ungluecklich"
                label="Unglücklich"
                checked={pageData[title] === 'ungluecklich'}
                onchange={onChange}
              />
              <FormCheck
                name={[title]}
                value="unzufrieden"
                label="Unzufrieden"
                checked={pageData[title] === 'unzufrieden'}
                onchange={onChange}
              />
              <FormCheck
                name={[title]}
                value="ok"
                label="Ok"
                checked={pageData[title] === 'ok'}
                onchange={onChange}
              />
              <FormCheck
                name={[title]}
                value="zufrieden"
                label="Zufrieden"
                checked={pageData[title] === 'zufrieden'}
                onchange={onChange}
              />
              <FormCheck
                name={[title]}
                value="gluecklich"
                label="Glücklich"
                checked={pageData[title] === 'gluecklich'}
                onchange={onChange}
              />
              <FormCheck
                name={[title]}
                value="keine_angabe"
                label="Keine Angabe"
                checked={pageData[title] === 'keine_angabe'}
                onchange={onChange}
              />
            </div>

            <div className="form-group row mt-5">
              <h5>{props.secondtitle}</h5>
              <TextArea
                label=""
                rows={3}
                name={[text]}
                value={pageData[text]}
                placeholder="Text hier eingeben..."
                maxlength={300}
                textlength={pageData.textlength}
                onchange={onChange}
              />
            </div>
            {error ? (
              <div className="alert alert-danger" role="alert">
                Bitte füllen Sie alle Felder aus!
              </div>
            ) : null}

            <div className="form-group row">
              {currentpagenumber > 1 && currentpagenumber !== pagecount ? (
                <Fragment>
                  <SendButton
                    classname="btn-light btn-lg mt-5 float-left"
                    value="Zurück"
                    icon="fas fa-long-arrow-alt-left"
                    onsubmit={getPrevPage}
                  />
                  <SendButton
                    classname="btn-primary btn-lg mt-5 float-right"
                    value="Weiter"
                    icon="fas fa-long-arrow-alt-right"
                    onsubmit={nextPage}
                  />
                </Fragment>
              ) : null}
              {currentpagenumber === 1 ? (
                <Fragment>
                  <SendButton
                    classname="btn-primary btn-lg mt-5 float-right"
                    value="Weiter"
                    icon="fas fa-long-arrow-alt-right"
                    onsubmit={nextPage}
                  />
                </Fragment>
              ) : null}
              {currentpagenumber === pagecount ? (
                <Fragment>
                  <SendButton
                    classname="btn-light btn-lg mt-5 float-left"
                    value="Zurück"
                    icon="fas fa-long-arrow-alt-left"
                    onsubmit={getPrevPage}
                  />
                  {Object.values(pageData)[0].length < 1 ? (
                    <div className="col">
                      <div
                        className="btn btn-outline-light btn-lg mt-5 float-right"
                        style={btnDisabled}
                        disabled
                      >
                        Absenden <i className="fas fa-paper-plane" />
                      </div>
                    </div>
                  ) : (
                    <SendButton
                      classname="btn-primary btn-lg mt-5 float-right"
                      value="Absenden"
                      icon={`${
                        pageData.send && isLoading
                          ? 'fas fa-circle-notch fa-spin'
                          : 'fas fa-paper-plane'
                      }`}
                      onsubmit={onSubmit}
                    />
                  )}
                </Fragment>
              ) : null}
            </div>
          </form>
          <hr />
          <p className="text-muted">
            <small className="float-left">
              <span className="text-danger">*</span> Erforderlich
            </small>
            <small className="float-right">
              Seite {currentpagenumber}/{pagecount}
            </small>
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default DynamicForm;
