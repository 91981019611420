import React, { Fragment } from 'react';

import HeaderSmall from '../Header';

const ThankYou = () => {
  return (
    <Fragment>
      <HeaderSmall title="Mitarbeiterbefragung" />
      <div className="card-body py-5 text-center">
        <p>Die Umfrage ist beendet.</p>
        <hr />
        <h2>
          Vielen Dank für Ihre Teilnahme!{' '}
          <span role="img" aria-label="applause">
            👏
          </span>
        </h2>
        <p className="mt-5">
          <small>Sie können dieses Fenster nun schließen.</small>
        </p>
      </div>
    </Fragment>
  );
};

export default ThankYou;
