import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import Answers from '../Answers';
import ExportToExcel from '../ExportToExcel';

import Logo from '../../assets/AC+-+Logo+-+05.2019.png';

const Dashboard = () => {
  const [answers, setAnswers] = useState([{}]);

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/api/get_answers.php'
    })
      .then(res => {
        setAnswers(res.data);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <Fragment>
      <div className="row p-3">
        <div className="col-md-4 text-left">
          <ExportToExcel excelData={answers} filename="answers" />
        </div>
        <div className="col-md-4 text-center">
          <img src={Logo} className="img-fluid" alt="AC Logo" />
        </div>
        <div className="col-md-4 text-right">
          <span className="btn btn-outline-secondary btn-sm">
            Logout <i className="fas fa-sign-out-alt" />
          </span>
        </div>
      </div>

      <div className="row px-3 mt-3">
        <div
          className="col text-center py-5"
          style={{ backgroundColor: '#12538F', color: '#ffffff' }}
        >
          <h1 className="font-weight-bold">Umfrage-Antworten</h1>
        </div>
      </div>

      <div className="row">
        <div className="col mx-2  text-center answers-table">
          <small className="text-muted font-italic">
            <i className="fas fa-long-arrow-alt-left mr-3" />
            Links/Rechts scrollen, um mehr von der Tabelle zu sehen...
            <i className="fas fa-long-arrow-alt-right ml-3" />
          </small>
          <Answers answers={answers} />
        </div>
      </div>
    </Fragment>
  );
};

export default Dashboard;
