import React from 'react';
import { Beforeunload } from './components/useUnload';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import MyState from './context/MyState';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Home from './components/pages/Home';
import ThankYou from './components/pages/ThankYou';

import Page from './components/pages/Page';

import Login from './components/pages/Login';
import Register from './components/pages/Register';
import Dashboard from './components/pages/Dashboard';
import CodeGen from './components/pages/CodeGen';
import Imprint from './components/pages/Imprint';
import Privacy from './components/pages/Privacy';
import NotFound from './components/pages/NotFound';

function App() {
  return (
    <Beforeunload onBeforeunload={() => 'Sind Sie sicher?!'}>
      <MyState>
        <Router>
          <div className="container">
            <div className="card my-5 shadow">
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/vielen-dank" component={ThankYou} />
                <Route exact path="/page/:pagenumber" component={Page} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/dashboard/codegen" component={CodeGen} />
                <Route exact path="/impressum" component={Imprint} />
                <Route exact path="/datenschutz" component={Privacy} />
                <Route exact path="/404" component={NotFound} />
                <Route component={NotFound} />
              </Switch>
            </div>
            <div className="row">
              <div className="col text-center">
                <p>
                  <a href="/impressum">Impressum</a> |{' '}
                  <a href="/datenschutz">Datenschutz</a>
                </p>
              </div>
            </div>
          </div>
        </Router>
      </MyState>
    </Beforeunload>
  );
}

export default App;
