import React, { useReducer } from 'react';

import MyContext from './MyContext';
import MyReducers from './MyReducers';
import {
  SET_ID,
  SET_AUTHCODE,
  SET_DATA,
  SET_LOADING,
  SET_ERROR,
  SET_PREVPAGE,
  SET_CODELOCKED
} from './types';

import formjson from '../JSON/forms.json';

const MyState = props => {
  const initialState = {
    id: '',
    authcode: '',
    loadingText: 'Einen kleinen Moment bitte...',
    errorText:
      'Der Code ist entweder ungültig oder Sie haben bereits teilgenommen.',
    jsondata: formjson,
    newData: [],
    isLoading: false,
    error: false,
    prevPage: false,
    codeLocked: false
  };

  const [state, dispatch] = useReducer(MyReducers, initialState);

  // Set ID
  const setId = value => dispatch({ type: SET_ID, payload: value });

  // Set Auth Code
  const setAuthcode = value => dispatch({ type: SET_AUTHCODE, payload: value });

  // Set Loading
  const setLoading = bool => dispatch({ type: SET_LOADING, payload: bool });

  // Set Error
  const setError = bool => dispatch({ type: SET_ERROR, payload: bool });

  // Set prevPage
  const setPrevPage = bool => dispatch({ type: SET_PREVPAGE, payload: bool });

  // Set codeLocked
  const setCodeLocked = bool =>
    dispatch({ type: SET_CODELOCKED, payload: bool });

  // Set Data
  const setData = newFormData => {
    dispatch({ type: SET_DATA, payload: [...state.newData, newFormData] });
  };

  return (
    <MyContext.Provider
      value={{
        id: state.id,
        setId,
        authcode: state.authcode,
        setAuthcode,
        loadingText: state.loadingText,
        errorText: state.errorText,
        isLoading: state.isLoading,
        setLoading,
        error: state.error,
        setError,
        prevPage: state.prevPage,
        setPrevPage,
        setCodeLocked,
        codeLocked: state.codeLocked,
        jsondata: state.jsondata,
        newData: state.newData,
        setData
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};

export default MyState;
