import React from 'react';

const FormCheck = props => {
  return (
    <div className="col-12 my-2">
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name={props.name}
          value={props.value}
          checked={props.checked}
          onChange={props.onchange}
        />
        <label className="form-check-label" htmlFor={props.name}>
          {props.label}
        </label>
      </div>
    </div>
  );
};

export default FormCheck;
