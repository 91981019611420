import React from 'react';

const SendButton = props => {
  return (
    <div className="col">
      <div
        className={props.classname}
        onClick={props.onsubmit}
        style={{ cursor: 'pointer' }}
      >
        {props.value} <i className={`${props.icon}`} />
      </div>
    </div>
  );
};

export default SendButton;
