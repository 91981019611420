import React, { useContext, useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import MyContext from '../../context/MyContext';

import Header from '../Header';
import TextInput from '../TextInput';
import SendButton from '../SendButton';

const btnDisabled = {
  cursor: 'not-allowed',
  borderStyle: 'dashed',
  borderColor: '#ccc',
  color: '#ccc'
};

const Home = props => {
  const homeContext = useContext(MyContext);
  const { setId, setAuthcode } = homeContext;

  const [data, setData] = useState({
    code: '',
    id: '',
    isloading: false,
    loadingText: 'Einen kleinen Moment bitte...',
    error: false,
    errorText:
      'Der Code ist entweder ungültig oder Sie haben bereits teilgenommen.',
    codeLocked: false,
    btnActive: false
  });

  const [codes, setCodes] = useState([]);

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/api/get_codes.php'
    })
      .then(res => {
        //console.log(res.data);
        setCodes(res.data);
      })
      .catch(err => console.log(err));
  }, []);

  const onCodeClick = e => {
    setData({
      ...data,
      code: e.target.innerHTML,
      id: e.target.id,
      codeLocked: true,
      btnActive: true
    });
  };

  const onChange = e => {
    setData({ ...data, [e.target.name]: e.target.value, error: false });
    codes.filter(code => {
      if (code.authcode === e.target.value) {
        //setData({ ...data, [e.target.name]: e.target.value });
        setTimeout(() => {
          setData({
            ...data,
            error: false,
            code: code.authcode,
            id: code.id,
            codeLocked: true,
            btnActive: true
          });
        }, 100);
      } else {
        setData({
          ...data,
          [e.target.name]: e.target.value,
          error: false,
          id: '',
          codeLocked: false,
          btnActive: false
        });
      }
      return null;
    });
  };

  const clearInput = () => {
    /* const postdata = {
      id: parseInt(data.id),
      code_used: 0
    }; */

    setData({
      ...data,
      code: '',
      id: '',
      error: false,
      codeLocked: false,
      btnActive: false
    });

    /* axios({
      method: 'POST',
      url: 'http://localhost:8888/forms/update_codes.php',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: postdata
    })
      .then(res => {
        console.log('Code un-locked.');
        setData({
          ...data,
          code: '',
          id: '',
          error: false,
          codeLocked: false,
          btnActive: false
        });
      })
      .catch(err => console.log(err)); */
  };

  const onSubmit = e => {
    e.preventDefault();
    setData({ ...data, isloading: true });

    axios({
      method: 'POST',
      url: '/api/check_user.php',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: {
        authcode: data.code
      }
    })
      .then(res => {
        //console.log(res.data);
        if (!res.data.code_used && res.data.authcode === data.code) {
          let storageItems = [{ authcode: res.data.authcode }];
          localStorage.setItem('cwQeJ6j6K8Ha5A', JSON.stringify(storageItems));
          setTimeout(() => {
            setId(res.data.id);
            setAuthcode(res.data.authcode);
            props.history.push('/page/1');
            //setData({ ...data, isloading: false, error: false });
          }, 1500);
        } else {
          localStorage.removeItem('cwQeJ6j6K8Ha5A');
          setTimeout(() => {
            setData({ ...data, isloading: false, error: true });
          }, 1500);
        }
      })
      .catch(err => {
        console.log(err);
        setTimeout(() => {
          setData({ ...data, isloading: false, error: true });
        }, 1500);
      });
  };

  return (
    <Fragment>
      <Header
        title="Mitarbeiterbefragung"
        text={`
              Wie zufrieden sind Sie als Mitarbeiter? In regelmäßigen Abständen möchten wir wissen, wie es in verschiedenen Bereichen mit der Zufriedenheit in unserem Unternehmen bestellt ist. Aus diesem Grund ist uns Ihre Meinung wichtig. Alle Antworten werden anonym erfasst. Es können also keine Schlüsse auf einzelne Personen gezogen werden. Die Umfrage dient dem Ziel, unser Unternehmen stetig zu verbessern, damit wir auch in Zukunft ein gutes Unternehmen, sowie ein guter Arbeitgeber sein können.
            `}
      />
      <div
        className="card-body text-center bg-light"
        //style={{ backgroundColor: '#ddd' }}
      >
        <form method="POST" onSubmit={onSubmit}>
          <div className="form-group row mt-5 py-3">
            <TextInput
              col="col-6 mx-auto"
              htmlfor="code"
              title="Bitte wählen Sie unten einen Teilnahme-Code (anklicken oder eintippen):"
              name="code"
              value={data.code || ''}
              onchange={onChange}
              placeholder=""
              required={true}
              codeselected={data.code !== '' ? true : false}
              deleteinput={clearInput}
            />
          </div>
          {data.error ? (
            <div className="form-group row">
              <div className="col">
                <small className="form-text text-danger">
                  <i className="fas fa-exclamation-triangle" /> {data.errorText}
                  .
                </small>
              </div>
            </div>
          ) : null}
          {data.isloading ? (
            <div className="form-group row">
              <div className="col">
                <small className="form-text">
                  {data.loadingText}{' '}
                  <i className="fas fa-circle-notch fa-spin" />
                </small>
              </div>
            </div>
          ) : null}
          <div className="form-group row">
            {data.btnActive ? (
              <SendButton
                classname="btn-primary btn-lg shadow-sm"
                value="Start"
                icon=""
                onsubmit={onSubmit}
              />
            ) : !data.btnActive ? (
              <div className="col">
                <div
                  className="btn btn-outline-light btn-lg btn-block"
                  style={btnDisabled}
                  disabled
                >
                  Start
                </div>
              </div>
            ) : (
              <SendButton
                classname="btn btn-primary btn-lg btn-block shadow-sm"
                value="Start"
                icon=""
                onsubmit={onSubmit}
              />
            )}
          </div>
        </form>
        <div className="row mt-5">
          <div className="col">
            <p>TEILNAHME-CODES:</p>
            <ul className="list-group d-flex flex-row flex-wrap">
              {codes.map((code, i) => {
                return code.code_used === '1' ||
                  (data.codeLocked && data.id === code.id) ? (
                  <li
                    className="list-group-item bg-light border text-muted m-1"
                    style={{ cursor: 'not-allowed' }}
                    key={i}
                  >
                    <strike>{code.authcode}</strike>
                  </li>
                ) : (
                  <li
                    className="list-group-item border m-1 shadow-sm code-item"
                    style={{ cursor: 'pointer' }}
                    key={i}
                  >
                    <span onClick={onCodeClick} id={code.id} className="">
                      {code.authcode}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p className="font-italic text-muted mt-5">
              Hinweis: Die Codes wurden zufällig generiert und können keine
              Rückschlüsse auf Ihre Person ziehen.
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
