import React, { useContext, Fragment } from 'react';
import MyContext from '../../context/MyContext';

import HeaderSmall from '../Header';
import DynamicForm from '../DynamicForm_new';

const Page = props => {
  const pageContext = useContext(MyContext);
  const { id, authcode, jsondata } = pageContext;

  const storage = localStorage.getItem('cwQeJ6j6K8Ha5A');
  if (!storage) {
    props.history.push('/');
  }

  if (parseInt(props.match.params.pagenumber) > jsondata.length) {
    props.history.push(`/404`);
    return null;
  }

  const pagenumber = parseInt(props.match.params.pagenumber);
  const pagecontent = jsondata[pagenumber - 1];
  const pagecount = jsondata.length;
  const pagehistory = props.history;

  if (jsondata) {
    return (
      <Fragment>
        <HeaderSmall title="Mitarbeiterbefragung" />
        <div className="card-body">
          <DynamicForm
            id={id}
            authcode={authcode}
            maintitle={pagecontent.maintitle}
            firsttitle={pagecontent.firsttitle}
            secondtitle={pagecontent.secondtitle}
            pagenumber={pagenumber}
            propsdata={pagecontent}
            currentpagenumber={pagenumber}
            pagecount={pagecount}
            pagehistory={pagehistory}
          />
        </div>
      </Fragment>
    );
  }
  return (
    <p>
      Tut uns leider, aber die Daten können momentan nicht geladen werden. Bitte
      versuchen Sie es später noch einmal
    </p>
  );
};

export default Page;
