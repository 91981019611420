import React, { Component } from 'react';
import axios from 'axios';

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      username: '',
      password: '',
      error: false,
      errorText: ''
    };
  }

  componentDidMount() {
    const storage = localStorage.getItem('formsapp');
    if (storage) {
      this.props.history.push('/dashboard');
    }
  }

  handleChange = event => {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const data = {
      email: this.state.email,
      username: this.state.username,
      password: this.state.password
    };
    axios({
      method: 'POST',
      url: '/api/create_user.php',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    }).then(response => {
      if (response.data.status === 409) {
        this.setState({
          errorText: 'Dieser Benutzer existiert bereits!',
          error: true
        });
      } else {
        this.props.history.push('/login');
      }
    });
  };

  render() {
    let classnameError = this.state.error
      ? 'alert alert-danger'
      : 'alert alert-danger invisible';
    return (
      <>
        <div className="HomeSub-cont">
          <div className="row py-3">
            <div className="col">
              <h1>Benutzer registrieren</h1>
              <p className="font-italic">
                Dieser Bereich ist nur für unser Team.
              </p>
            </div>
          </div>
          {this.state.error ? (
            <div className="row">
              <div className="col">
                <div className={classnameError} role="alert">
                  <strong>{this.state.errorText}</strong> Bitte geben Sie eine
                  andere Email-Adresse ein.
                </div>
              </div>
            </div>
          ) : null}
          <div className="row py-3">
            <div className="col">
              <div className="card bg-blue shadow">
                <form className="p-5 needs-validation" onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <label htmlFor="email">Email-Adresse</label>
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      value={this.state.email}
                      onChange={this.handleChange}
                      placeholder="Email-Adresse"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="username">Benutzername</label>
                    <input
                      type="text"
                      name="username"
                      className="form-control"
                      value={this.state.username}
                      onChange={this.handleChange}
                      placeholder="Gewünschter Benutzername"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Passwort</label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      value={this.state.password}
                      onChange={this.handleChange}
                      placeholder="Passwort"
                      required
                    />
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Registrieren
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Register;
