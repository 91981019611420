import React, { useContext, useState, Fragment } from 'react';
import axios from 'axios';
import './codegen.css';

import MyContext from '../../context/MyContext';

const btnDisabled = {
  cursor: 'not-allowed',
  borderStyle: 'dashed',
  borderColor: '#ccc',
  color: '#ccc'
};

const CodeGen = () => {
  const codegenContext = useContext(MyContext);
  const [range, setRange] = useState(50);
  const [charNum, setCharNum] = useState(6);
  const [codes, setCodes] = useState([]);
  const [generated, setGenerated] = useState(false);

  const { setLoading, isLoading } = codegenContext;

  const changeRange = e => {
    setRange(e.target.value);
  };

  const changeCharNum = e => {
    setCharNum(e.target.value);
  };

  const generateCodes = () => {
    setLoading(true);
    const USABLE_CHARACTERS = 'abcdefghijklmnopqrstuvwxyz0123456789'
      .toUpperCase()
      .split('');

    let codesArray = Array.from({ length: parseInt(range) }, () => {
      return Array.from(Array(parseInt(charNum)))
        .fill(null)
        .map(() => {
          return USABLE_CHARACTERS[
            Math.floor(Math.random() * USABLE_CHARACTERS.length)
          ];
        })
        .join('');
    });

    setTimeout(() => {
      setCodes(...codes, codesArray);
      setGenerated(true);
      setLoading(false);
    }, 1000);
  };

  const setNew = () => {
    setRange(50);
    setCharNum(6);
    setCodes([]);
    setGenerated(false);
  };

  const saveToDB = e => {
    if (window.confirm('Sind Sie sicher?')) {
      console.log('saveToDB agreed.');
      e.preventDefault();
      setLoading(true);

      const data = {
        /* id: parseInt(props.id),
        authcode: props.authcode,
        code_used: 1,
        newData: newData */
        codes
      };

      //console.log(data);

      axios({
        method: 'POST',
        url: '/api/create_codes.php',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data
      })
        .then(res => {
          /* if (res.data.message === 'success') {
            setTimeout(() => {
              console.log(res.data.message);
              console.log(res.data);
              setLoading(false);
            }, 1500);
          } else {
            setTimeout(() => {
              alert(
                `⚠️ Fehler bei der Übermittlung. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an Ihren für die Befragung zuständigen Ansprechpartner.\n\nFehlermeldung:\n${res.data}`
              );
            }, 1500);
          } */
          console.log(res.data);
          setLoading(false);
        })
        .catch(err => {
          alert(
            `⚠️ Fehler bei der Übermittlung. Bitte versuchen Sie es später nocheinmal oder wenden Sie sich an Ihren Ansprechpartner der Befragung.\n\nFehlermeldung:\n${err}`
          );
          setLoading(false);
        });
    } else {
      console.log('saveToDB cancelled.');
    }
  };

  return (
    <div className="card-body">
      <div className="row">
        <div className="col">
          <h3 className="font-weight-bold">CodeGenerator</h3>
          <form>
            <div className="form-group my-5">
              <label htmlFor="formControlRange">
                Wie viele Codes benötigen Sie?
              </label>
              <div style={{ width: '98.7%' }} className="text-center mb-3">
                <span
                  style={{ fontSize: '1.2rem' }}
                  className="badge badge-light"
                >
                  {range}
                </span>
              </div>
              <input
                type="range"
                name="range"
                min="1"
                max="100"
                value={range}
                className="form-control-range slider"
                onChange={changeRange}
              />
            </div>
            <div className="form-group my-5">
              <label htmlFor="formControlRange">
                Wie viele Zeichen soll ein Code haben?
              </label>
              <div className="text-center mb-3">
                <span
                  style={{ fontSize: '1.2rem' }}
                  className="badge badge-light"
                >
                  {charNum}
                </span>
              </div>
              <input
                type="range"
                name="range"
                min="4"
                max="15"
                value={charNum}
                className="form-control-range slider"
                onChange={changeCharNum}
              />
            </div>
          </form>
          <hr />
          {generated && !isLoading ? (
            <Fragment>
              <div className="btn btn-light" style={btnDisabled} disabled>
                Codes generieren
              </div>
              <div className="btn btn-danger float-right" onClick={setNew}>
                Neu starten
              </div>
            </Fragment>
          ) : (
            <div className="btn btn-primary" onClick={generateCodes}>
              Codes generieren
            </div>
          )}
          <hr />
          {isLoading ? (
            <Fragment>
              <h2 className="text-center">
                <i
                  className="fas fa-cog fa-spin fa-2x text-primary"
                  style={{ opacity: '0.5' }}
                />
              </h2>
            </Fragment>
          ) : null}
          <div className="mt-5">
            {codes.length > 0 ? (
              <Fragment>
                <div className="row">
                  <div className="col">
                    <p>Ergebnis:</p>

                    {codes.map((code, i) => {
                      return (
                        <div className="badge badge-secondary m-1" key={i}>
                          {code}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col">
                    <div
                      className="btn btn-primary btn-block"
                      onClick={saveToDB}
                    >
                      In Datenbank speichern
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeGen;
