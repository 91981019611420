import React, { Fragment } from 'react';
import HeaderSmall from '../Header';

const NotFound = props => {
  return (
    <Fragment>
      <HeaderSmall title="404 - Seite nicht gefunden!" />
      <div className="card-body">
        <div className="row">
          <div className="col">
            <p>
              Tut uns leid, aber die von Ihnen angegebene Seite konnte leider
              nicht gefunden werden.
            </p>
            <p>
              Normalerweise müssen Sie für diese Umfrage nichts in der
              Adresszeile eingeben. Falls Sie dies doch gemacht haben, sind Ihre
              bisherigen Eingaben leider gelöscht. Sie können aber von vorne
              beginnen:
            </p>
            <p>
              <a className="btn btn-primary" href="/">
                &larr; Zurück zum Start
              </a>
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NotFound;
