import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const ExportToExcel = ({ excelData, fileName }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = (excelData, fileName) => {
    let date = new Date().toLocaleDateString().replace(/\./g, '_');
    fileName = 'answers' + date;
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div
      className="btn btn-sm export-btn"
      onClick={e => exportToExcel(excelData, fileName)}
    >
      Export für Excel (.xlsx) <i className="far fa-file-excel" />
    </div>
  );
};

export default ExportToExcel;
