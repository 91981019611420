import React from 'react';

const Header = props => {
  return (
    <div
      className="card-header p-5"
      style={{ backgroundColor: 'purple', color: '#ffffff' }}
    >
      <h1>{props.title}</h1>
      <p>{props.text}</p>
    </div>
  );
};

export default Header;
