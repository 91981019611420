import React from 'react';

const Answers = ({ answers }) => {
  const AnswerRow = (answer, index) => {
    return (
      <tr key={index}>
        <td> {index} </td>
        <td>{answer.authcode}</td>
        <td>{answer.code_used}</td>
        <td>{answer.arbeitsmittel}</td>
        <td className="truncate">{answer.arbeitsmittel_text}</td>
        <td>{answer.arbeitsumgebung}</td>
        <td className="truncate">{answer.arbeitsumgebung_text}</td>
        <td>{answer.arbeitszeiten}</td>
        <td className="truncate">{answer.arbeitszeiten_text}</td>
        <td>{answer.aufgabe_taetigkeiten}</td>
        <td className="truncate">{answer.aufgabe_taetigkeiten_text}</td>
        <td>{answer.auslastung}</td>
        <td className="truncate">{answer.auslastung_text}</td>
        <td>{answer.fuehrungskraefte}</td>
        <td className="truncate">{answer.fuehrungskraefte_text}</td>
        <td>{answer.gehalt}</td>
        <td className="truncate">{answer.gehalt_text}</td>
        <td>{answer.gesundheit}</td>
        <td className="truncate">{answer.gesundheit_text}</td>
        <td>{answer.karriere}</td>
        <td className="truncate">{answer.karriere_text}</td>
        <td>{answer.kollegen}</td>
        <td className="truncate">{answer.kollegen_text}</td>
        <td>{answer.kommunikation}</td>
        <td className="truncate">{answer.kommunikation_text}</td>
        <td>{answer.organisation}</td>
        <td className="truncate">{answer.organisation_text}</td>
        <td>{answer.urlaub}</td>
        <td className="truncate">{answer.urlaub_text}</td>
        <td>{answer.weiterbildung}</td>
        <td className="truncate">{answer.weiterbildung_text}</td>
        <td style={{ whiteSpace: 'nowrap' }}>{answer.created}</td>
      </tr>
    );
  };

  const AnswersTable = answers.map((item, index) => AnswerRow(item, index));

  const tableHeader = (
    <thead
      style={{
        backgroundColor: '#12538F',
        color: '#ffffff',
        whiteSpace: 'nowrap'
      }}
    >
      <tr>
        <th>ID</th>
        <th>Authcode</th>
        <th>Code used</th>
        <th>Arbeitsmittel</th>
        <th>Arbeitsmittel Text</th>
        <th>Arbeitsumgebung</th>
        <th>Arbeitsumgebung Text</th>
        <th>Arbeitszeiten</th>
        <th>Arbeitszeiten Text</th>
        <th>Aufgabe/Tätigkeiten</th>
        <th>Aufgabe/Tätigkeiten Text</th>
        <th>Auslastung</th>
        <th>Auslastung Text</th>
        <th>Führungskräfte</th>
        <th>Führungskräfte Text</th>
        <th>Gehalt</th>
        <th>Gehalt Text</th>
        <th>Gesundheit</th>
        <th>Gesundheit Text</th>
        <th>Karriere</th>
        <th>Karriere Text</th>
        <th>Kollegen</th>
        <th>Kollegen Text</th>
        <th>Kommunikation</th>
        <th>Kommunikation Text</th>
        <th>Organisation</th>
        <th>Organisation Text</th>
        <th>Urlaub</th>
        <th>Urlaub Text</th>
        <th>Weiterbildung</th>
        <th>Weiterbildung Text</th>
        <th>Erstellt am</th>
      </tr>
    </thead>
  );

  return (
    <div className="table-responsive">
      <table className="table table-striped shadow-sm">
        {tableHeader}
        <tbody>{AnswersTable}</tbody>
      </table>
    </div>
  );
};

export default Answers;
