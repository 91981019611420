import React from 'react';

const TextArea = props => {
  return (
    <div className="col-12">
      <div className="form-group">
        <label htmlFor="exampleFormControlTextarea1">{props.label}</label>
        <textarea
          className="form-control"
          rows={props.rows}
          name={props.name}
          onChange={props.onchange}
          value={props.value}
          placeholder={props.placeholder}
          maxLength={props.maxlength}
        ></textarea>
        <small className="form-text text-muted">
          Noch{' '}
          {props.textlength >= props.maxlength / 2 ? (
            <span className="text-success font-weight-bold">
              {props.textlength}
            </span>
          ) : props.textlength < props.maxlength && props.textlength > 30 ? (
            <span className="text-warning font-weight-bold">
              {props.textlength}
            </span>
          ) : (
            <span className="text-danger font-weight-bold">
              {props.textlength}
            </span>
          )}{' '}
          Zeichen verfügbar.
        </small>
      </div>
    </div>
  );
};

export default TextArea;
