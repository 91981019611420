import React from 'react';

const TextInput = props => {
  return (
    <div className={props.col}>
      <label htmlFor={props.htmlfor}>
        {props.required ? <span className="text-danger">*</span> : null}{' '}
        {props.title}
      </label>
      <div className="input-group">
        <input
          className="form-control"
          type="text"
          name={props.name}
          value={props.value}
          onChange={props.onchange}
          placeholder={props.placeholder}
        />
        {props.codeselected ? (
          <div
            className="input-group-append"
            style={{ cursor: 'pointer' }}
            onClick={props.deleteinput}
            title="Löschen"
          >
            <span className="input-group-text bg-danger text-white">
              &times;
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TextInput;
