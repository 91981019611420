import React, { Component, Fragment } from 'react';
import axios from 'axios';

import Logo from '../../assets/AC+-+Logo+-+05.2019.png';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      isLoading: false,
      error: false,
      success: false,
      loggedin: false,
      user: ''
    };
  }

  componentDidMount() {
    const storage = localStorage.getItem('formsapp');
    if (storage) {
      this.props.history.push('/dashboard');
    }
  }

  handleChange = event => {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  onSubmit = e => {
    e.preventDefault();
    console.log(this.state.email);
    axios({
      method: 'POST',
      url: '/api/login.php',
      data: {
        email: this.state.email,
        password: this.state.password
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then(response => {
      console.log(response);
      if (response.data.message === 'Login failed.') {
        this.setState({ error: true });
        return;
      } else {
        this.setState({
          success: true,
          loggedin: true,
          user: response.data.username
        });
        let storageItems = [
          { loggedin: this.state.loggedin, user: this.state.user }
        ];
        localStorage.setItem('formsapp', JSON.stringify(storageItems));
        this.props.history.push('/dashboard');
      }
    });
  };

  render() {
    let classnameSuccess = this.state.success
      ? 'alert alert-success'
      : 'alert alert-success invisible';
    let classnameError = this.state.error
      ? 'alert alert-danger'
      : 'alert alert-danger invisible';
    return (
      <Fragment>
        <div className="row">
          <div className="col p-3">
            <div>
              <div className="row p-3">
                <div className="col">
                  <h1 style={{ color: '#12538F' }}>Interner Bereich</h1>
                  <p className="font-italic" style={{ color: '#cd1640' }}>
                    Dieser Bereich ist nur für unser Team.
                  </p>
                </div>
                <div className="col">
                  <img src={Logo} className="img-fluid" alt="AC Logo" />
                </div>
              </div>
              {this.state.succes ? (
                <div className="row">
                  <div className="col">
                    <div className={classnameSuccess} role="alert">
                      <strong>Anmeldung erfolgreich!</strong> Sie werden gleich
                      zum Dashboard weitergeleitet
                      <span className="loading" />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col">
                    <div className={classnameError} role="alert">
                      <strong>Anmeldung fehlgeschlagen!</strong>
                    </div>
                  </div>
                </div>
              )}
              <div className="row px-5">
                <div className="col">
                  <div
                    className="card text-white shadow"
                    style={{ backgroundColor: '#12538F' }}
                  >
                    <form
                      className="p-5 needs-validation"
                      onSubmit={this.onSubmit}
                    >
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Email-Adresse
                        </label>
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          value={this.state.email}
                          onChange={this.handleChange}
                          placeholder="Email-Adresse"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Passwort</label>
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          value={this.state.password}
                          onChange={this.handleChange}
                          placeholder="Passwort"
                          required
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn"
                        style={{ backgroundColor: '#cd1640', color: '#ffffff' }}
                      >
                        Login
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="row py-3">
                <div className="col text-center">
                  {this.state.isLoading ? (
                    <h2>
                      <i className="fas fa-sync fa-spin" />
                    </h2>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Login;
