import {
  SET_ID,
  SET_AUTHCODE,
  SET_DATA,
  SET_LOADING,
  SET_ERROR,
  SET_PREVPAGE
} from './types';

export default (state, action) => {
  switch (action.type) {
    case SET_ID:
      return {
        ...state,
        id: action.payload
      };
    case SET_AUTHCODE:
      return {
        ...state,
        authcode: action.payload
      };
    case SET_DATA:
      return {
        ...state,
        newData: action.payload
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case SET_PREVPAGE:
      return {
        ...state,
        prevPage: action.payload
      };
    default:
      return state;
  }
};
